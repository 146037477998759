.testimonials-section {
  position: relative;
  margin: 170px 0 40px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 330px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%);
  }

  .testimonials-grid {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin: 0 -15px;

    .testimonials-column {
      padding: 0 15px;
      width: 33.33%;
    }

    .testimonial {
      padding: 30px 20px;
      border-radius: 20px;
      margin-bottom: 30px;
      display: inline-block;

      .testimonial-author {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px;

        .testimonial-name {
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0.0025em;
          color: $--color-default;
        }
        .testimonial-rating {

        }
      }
    }
  }

}

@media (max-width: 1024px) {

  .testimonials-section {
    margin: 100px 0 60px;

    &:after {
      height: 265px;
    }

    .testimonials-grid {
      margin: 0 -12px;
      width: calc(100% + 24px);

      .testimonial {
        margin-bottom: 24px;
      }

      .testimonials-column {
        width: 50%;
        padding: 0 12px;
      }
    }
  }
}

@media (max-width: 768px) {
  .testimonials-section {
    .testimonials-grid {

      .testimonials-column {
        width: 100%;
      }

      .testimonial {
        margin-bottom: 24px;
      }
    }
  }
}
