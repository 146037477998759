.drug-banner {

  .title-head {
    margin-bottom: 20px;
    max-width: 70%;
  }

  .btn {
    max-width: 250px;
  }

  .banner-content {
    padding-right: 100px;

    h2 {
      margin: 25px 0 10px;
      font-weight: 400;
    }

    .banner-description {
      margin-bottom: 45px;

      p {
        font-weight: 400;
      }

    }
  }

  .treatment-card .treatment-image img {
    right: 11px;
  }

}

@media (max-width: 1024px) {
  .drug-banner {

    .row {
      position: relative;
    }

    .treatment-card {
      position: sticky;
      top: 20px;
    }

    .title-head {
      max-width: 100%;
    }

    .banner-content {
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .drug-banner {

    .treatment-card {
      margin-bottom: 30px;
    }

    .banner-content {

      .banner-description {
        margin-bottom: 20px;
      }

      .btn {
        max-width: 100%;
      }

      .title-head {
        margin-bottom: 10px;

        h1 {
          font-size: 30px;
          line-height: 105%;
        }
      }
    }

    .row {

      .col-m-12 {

        &:nth-of-type(2) {
          order: -1;
        }
      }
    }
  }
}
