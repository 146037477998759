.footer {
  padding: 60px 0 50px;
  background-color: $--color-default;

  .footer-block-tablet {
    display: none;
  }

  .wpcf7-not-valid-tip {
    margin-top: 10px;
  }

  form .wpcf7-response-output {
    margin: 10px 0;
  }

  .footer-row {
    display: flex;
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px solid $--color-grey-opacity;
    margin-bottom: 20px;

    .footer-column {
      padding-top: 10px;


      &:nth-of-type(1) {
        border-right: 1px solid $--color-grey-opacity;
        padding-right: 35px;
        width: 31%;
      }

      &:nth-of-type(2) {
        width: 69%;
        padding-left: 40px;
      }

    }
  }

  .footer-logo {
    margin-bottom: 60px;

    img {
      max-width: 225px;
      width: 100%;
      object-fit: cover;
    }
  }

  .footer-contacts {
    margin-bottom: 27px;

    .contact-link {
      display: flex;
      align-items: center;

      &:hover a {
        color: $--color-purple;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      a {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $--color-white;
        display: flex;
        align-items: center;
        width: fit-content;

        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px;
          margin-right: 10px;
        }

      }

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $--color-grey;
        margin-left: 10px;
      }

    }

    .contact-link-address {

      div {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $--color-white;
        display: flex;
        align-items: center;
        width: fit-content;

        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px;
          margin-right: 10px;
          background-image: url(../img/icons/ico-address.svg);
        }
      }

    }

    .contact-link-phone {

      a:before {
        background-image: url(../img/icons/ico-phone.svg);
      }
    }

    .contact-link-mail {

      a:before {
        background-image: url(../img/icons/ico-mail.svg);
      }
    }

  }

  .footer-socials {
    display: flex;
    align-items: center;

    .social-link {
      display: block;
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 32px;
    }

    .social-link + .social-link {
      margin-left: 40px;
    }

    .social-link-youtube {
      background-image: url(../img/icons/ico-youtube.svg);
    }

    .social-link-instagram {
      background-image: url(../img/icons/ico-instagram.svg);
    }

  }

  .footer-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;

    form {
      max-width: 309px;
      width: 100%;

      input {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.005em;
        padding: 0 50px;
        border-radius: 60px;
        outline: none;
        box-shadow: none;
        border: 0;
        color: $--color-grey;
        background-repeat: no-repeat;
        background-position: 20px 50%;
        background-size: 20px;
        background-image: url(../img/icons/ico-mail-input.svg);

        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: $--color-grey;
        }
      }
    }

    .h6 {
      color: $--color-white;
      max-width: 360px;
    }
  }

  .footer-description {

    p {
      color: $--color-grey;
      margin-bottom: 26px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

}

@media (max-width: 1024px) {
  .footer {
    padding: 55px 0 45px;

    .footer-logo {
      margin-bottom: 42px;
    }

    .footer-row {
      flex-wrap: wrap;
      padding-bottom: 30px;

      .footer-column {

        &:nth-of-type(1) {
          border-right: 0;
        }

        &:nth-of-type(1), &:nth-of-type(2) {
          width: 100%;
          padding: 0;
        }

        &:nth-of-type(2) {
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
        }

      }
    }

    .footer-block-pc {
      display: none;
    }
    .footer-block-tablet {
      display: block;
      width: 50%;
      padding-top: 15px;
      padding-left: 20px;

      .footer-contacts {
        margin-bottom: 20px;
      }
    }
    .footer-form {
      width: 50%;
      display: block;
      border-right: 1px solid $--color-grey-opacity;
      padding-right: 34px;
      padding-top: 15px;
      margin-bottom: 26px;

      .h6 {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 42px 0 25px;

    .footer-row {
      padding-bottom: 16px;
    }

    .footer-logo {
      margin-bottom: 30px;
    }

    .footer-form {
      width: 100%;
      padding-top: 0;
      margin-bottom: 40px;
      padding-right: 0;
      border-right: 0;
    }
    .footer-block-tablet {
      padding: 0 0 25px;
      width: 100%;
    }
    .footer-description p {
      margin-bottom: 10px;
    }
  }
}
