* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body::-webkit-scrollbar {
  background: $--color-grey-light;
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background: $--color-purple;
  border-radius: 500px;
}

table {
  width: 100%;
}

.back-grey {
  background-color: $--color-grey-light;
}


.treatment-card {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  height: 450px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .treatment-image {
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
    .image-hover {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
    }
  }

  .tags-wrapper {
    margin: 0 -2.5px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 5px);
    margin-bottom: 27px;

    .tag {
      padding: 0 2.5px;
      margin-bottom: 5px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $--font-third;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.0125em;
        padding: 0 9px;
        height: 19px;
        color: $--color-white;
        background-color: $--color-grey;
        border-radius: 500px;
        transition: 0.3s;
      }
    }
  }

  .h3 {
    margin-bottom: 20px;
  }

  .link-black {

  }

  .btn {
    margin-top: auto;
    justify-content: flex-start;
    padding-left: 50px;
    max-width: 270px;
  }

  &:hover {

    .image-hover {
      opacity: 1;
      visibility: visible;
      overflow: visible;
    }

    .image {
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
    }

    .tags-wrapper {

      .tag {

        span {
          background-color: $--color-default;
        }
      }
    }

    .btn {
      background-color: $--color-purple;
      color: $--color-white;
    }
  }

}

.testimonial-rating {
  display: flex;
  align-items: center;

  .star + .star {
    margin-left: 6px;
  }
}

.star {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icons/rating-star.svg);
}

@media (max-width: 1024px) {
  .treatment-card {

    &:hover .tags-wrapper .tag span {
      background-color: $--color-grey;
    }

    .tags-wrapper {
      margin-bottom: 30px;

      .tag {

        span {
          font-weight: 500;
          letter-spacing: 0.175px;
          line-height: normal;
          font-family: $--font-default;
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .treatment-card {
    height: 425px;
    padding: 40px 20px;

    .tags-wrapper {
      margin-bottom: 10px;
    }
  }

  h3, .h3 {
    font-size: 30px;
    line-height: 105%;
  }
}
