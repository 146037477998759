.block-with-request {
  margin: 150px 0;

  .title-block {
    margin-bottom: 20px;
  }

  .description {

    .link-purple {
      margin-top: 50px;
      display: flex;
      width: fit-content;
    }
  }

  p:has(strong) {
    font-size: 18px;
  }

  .col-lg-6:nth-of-type(1) {
    align-content: flex-end;
    padding-bottom: 30px;
  }

  .request-block {
    display: flex;
    position: relative;
    height: 347px;
    border-radius: 30px;
    padding: 30px;
    background-image: url(../img/request-back.png);
    background-repeat: no-repeat;
    background-position: right;

    .request-info {
      max-width: 220px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .h5 {
        margin-bottom: 30px;
      }
    }

    .image-wrapper {
      position: absolute;
      bottom: 0;
      right: 22px;

      img {
        max-width: 263px;
        width: 100%;
        object-fit: cover;
      }
    }

    .image-wrapper-tablet, .image-wrapper-mobile {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .block-with-request {
    margin: 100px 0;

    .description {
      margin-bottom: 30px;
    }

    .request-block {
      background-image: url(../img/request-bakc-tablet.png);

      .request-info  {

        .h5 {
          margin-bottom: 25px;
        }
      }

      .image-wrapper {
        right: 50px;
      }

      .image-wrapper-tablet {
        display: block;
      }

      .image-wrapper-pc {
        display: none;
      }

      .request-info {
        max-width: 50%;
      }
    }

  }
}

@media (max-width: 768px) {
  .block-with-request {
    margin: 60px 0;

    .title-block {
      margin-bottom: 10px;
    }

    .col-lg-6:nth-of-type(1) {
      padding-bottom: 0;
    }

    .description {

      .link-purple {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: $--color-purple;
        color: $--color-white;
        border-radius: 500px;
        height: 40px;
      }
    }

    .request-block {
      flex-wrap: wrap;
      height: initial;
      padding: 0;
      justify-content: center;
      background-image: url(../img/request-back-mob.png);
      background-position: 0 100%;

      .h5 {
        line-height: initial;
        margin-bottom: 20px;
      }

      .btn {
        max-width: 100%;
        margin-bottom: 35px;
      }

      .request-info {
        max-width: 100%;
        padding: 30px 25px 0;
      }

      .image-wrapper {
        position: initial;
      }

      .image-wrapper-tablet {
        display: none;
      }
      .image-wrapper-mobile {
        display: block;
      }
    }
  }
}
