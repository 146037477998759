.about-banner {
  margin: 30px 0 50px;

  .title-head {
    margin-bottom: 20px;
  }

  .description {

    p {
      line-height: normal;
    }
  }

  .image-wrapper {

    img {
      height: 376px;
      width: 100%;
      object-fit: cover;
      border-radius: 30px;
    }
  }

}

.content-section {
  margin: 50px 0 130px;

  .title-block {
    margin-bottom: 20px;
  }

  .description {

    p {

    }
  }
}

.about-treatments {
  position: relative;
  overflow: hidden;
  margin: 130px 0 120px;

  .title-block {
    margin-bottom: 20px;
  }

  &:before {
    content: "";
    display: block;
    background: #fff;
    width: 30%;
    height: 450px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .content-wrapper {
    background: #fff;
    z-index: 2;
  }

  h5 {
    margin: 30px 0 10px;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    color: $--color-purple-dark;
  }
}

.about-treatments-slider {
  padding-bottom: 46px;
  .swiper-button-prev, .swiper-button-next {
    bottom: 0;
    top: unset;
    width: 24px;
    height: 24px;
  }
  .swiper-button-next {
    transform: scale(-1);
  }
  .swiper-button-prev {
    right: 54px;
    left: unset;
  }
}



@media (max-width: 1024px) {
  .about-treatments {

    &:before {
      display: none;
    }

    .content-block {
      margin-bottom: 20px;
    }

    .content-wrapper {
      z-index: initial;
      background: transparent;
    }
  }
}