.block-with-image {
  margin: 150px 0;

  .image-wrapper-tablet, .image-wrapper-mobile {
    display: none;
  }

  .image-wrapper {

    img {
      border-radius: 30px;
      object-fit: cover;
    }
  }

  .image-wrapper-pc {
    position: sticky;
    top: 20px;
  }

  .title-block-tablet {
    display: none;
  }


  .block-content {

    h6 {
      margin: 20px 0 10px;
    }

    p {

      &:has(strong) {
        font-size: 24px;
        margin: 20px 0 10px;
      }
    }

    ul {
      padding-left: 10px;
      margin: 15px 0;

      li {
        color: $--color-default;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.04px;
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: $--color-default;
          margin-right: 10px;
        }

        a {
          color: $--color-default;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.04px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .block-with-image {
    margin: 100px 0;

    .image-right {
      order: -1;
    }

    .title-block-pc {
      display: none;
    }

    .title-block-tablet {
      display: block;
    }

    .image-wrapper  {
      margin-bottom: 30px;

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    .image-wrapper-pc {
      display: none;
    }

    .image-wrapper-tablet {
      display: block;
    }

  }
}

@media (max-width: 768px) {
  .block-with-image {
    margin: 60px 0;

    .image-wrapper {
      margin-bottom: 20px;
    }

    .image-wrapper-tablet {
      display: none;
    }
    .image-wrapper-mobile {
      display: block;
    }
  }
}
