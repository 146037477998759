.card-list-with-icons {
  margin: 150px 0;

  .title-block {
    justify-content: center;
  }

  .description {
    margin-bottom: 20px;
    width: 100%;
  }

  .row {

    .col-lg-12 {

      &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
        width: 33.33333333%;
      }
    }
  }

  .card-with-icon {
    padding: 50px 40px 40px;
    border-radius: 30px;
    height: calc(100% - 30px);
    margin-bottom: 30px;

    .h5 {
      display: flex;
      margin-bottom: 10px;

      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-top: 5px;
      }
    }
  }

}

.card-list-with-icons.col-cards-1 {
  .row {
    width: 75%;

    .col-lg-12 {
      width: 100% !important;
      padding: 0;
    }
  }
  .title-block {
    width: 25%;
    align-items: flex-start;
    justify-content: flex-start;
  }
}


@media (max-width: 1024px) {
  .card-list-with-icons {
    margin: 100px 0;

    .card-with-icon {
      margin-bottom: 20px;
      height: initial;
      padding: 50px 30px;
    }

    .row {
      .col-lg-12 {

        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
          width: 100%;
        }
      }
    }
  }

  .card-list-with-icons.col-cards-1 {
    .row {
      width: 100%;
    }
    .title-block {
      width: 100%;
    }
  }

}

@media (max-width: 768px) {

  .card-list-with-icons {
    margin: 60px 0;

    .title-block {
      justify-content: flex-start;
    }

    .card-with-icon {
      padding: 30px 25px;
      margin-bottom: 10px;
    }

  }
}
