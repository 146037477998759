.blog-content-section {
  margin: 40px 0 130px;

  .title-head {
    margin-bottom: 20px;
  }

  .blog-content {
    max-width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 2px;
    margin: 30px 0;

    th, td {
      text-align: start;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: .0025em;
      color: $--color-grey;
      padding: 10px;

    }

    th {
      font-weight: 700;
    }

    tr {
      border-bottom: 1px solid $--color-grey-opacity;

      td, th {

        &:nth-of-type(1){
          padding-left: 0;
        }
      }
    }

  }

  a {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: .0025em;
    color: $--color-default;
    word-break: break-word;

    &:hover {
      color: $--color-purple;
    }
  }

  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    letter-spacing: 0.3px;
    color: $--color-default;
    margin: 20px 0 10px;
  }

  ul, ol {
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: .0025em;
      color: $--color-default;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

}