.banner-image {

  .btn-wrapper-mobile {
    display: none;
  }

  .row {
    align-items: flex-end;
  }

  .col-lg-7 {
    padding-bottom: 54px;
  }

  .banner-title {
    font-size: 50px;
    font-weight: 323;
    margin-bottom: 16px;
    line-height: 52px;
    letter-spacing: -0.02em;
    color: $--color-default;
  }

  .h6 {
    margin-bottom: 50px;
  }

  .image-wrapper {

    img {
      width: 100%;
      object-fit: cover;
    }
  }

}

@media (max-width: 1024px) {
  .banner-image {

    .col-lg-7 {
      padding-bottom: 0;
    }

    .btn-purple {
      max-width: 100%;
    }

    .banner-title {
      font-size: 35px;
      line-height: 39px;
      margin-bottom: 10px;
      font-weight: 400;
    }

    .h6 {
      margin-bottom: 30px;
    }

  }
}

@media (max-width: 768px) {
  .banner-image {

    .banner-title {
      font-size: 36px;
      line-height: normal;
      letter-spacing: -0.72px;
    }

    .h6 {
      font-size: 16px;
      line-height: normal;
      letter-spacing: 0.08px;
      margin-bottom: 0;
    }

    .btn-wrapper-pc {
      display: none;
    }

    .btn-wrapper-mobile {
      display: block;
      margin-top: 10px;
    }
  }
}
