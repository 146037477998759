.card-list-section {
  margin: 125px 0 115px;

  .title-block {
    margin-bottom: 25px;
  }

  .description {
    margin-bottom: 25px;

    p {
      max-width: 770px;
      font-weight: 400;
    }
  }

  .card-steps-list {
    width: 100%;
    counter-reset: list;

    .steps-card {
      padding: 30px 30px 30px 25px;
      position: relative;
      width: 100%;
      border-radius: 30px;
      margin-bottom: 30px;
      height: initial;

      .card-description {

        p {
          max-width: 480px;
        }
      }

      .image-wrapper {
        display: flex;
        justify-content: flex-end;

        img {
          object-fit: cover;
          max-width: 465px;
          width: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      .image-wrapper-tablet, .image-wrapper-mobile {
        display: none;
      }

      .card-content {
        display: flex;
        flex-direction: column;

        .card-step {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.005em;
          margin-bottom: 20px;
          color: $--color-purple;

          &:after {
            counter-increment: list;
            content: counter(list) "";
            margin-left: 5px;
          }
        }
        .h5 {
          margin-bottom: 10px;
        }

        .link-purple {
          margin-top: auto;
        }
      }



    }

  }
}


.card-list-section-half {
  margin: 240px 0 150px;



  .image-wrapper {
    margin-top: -90px;
    display: flex;
    justify-content: center;

    img {
      max-width: 465px;
      width: 100%;
      object-fit: cover;
    }
  }
  .card-steps-list {
    .steps-card {
      height: calc(100% - 30px);

      .card-content .card-step {
        margin-bottom: 10px;
      }
    }
  }
}

.page-template-index, .page-id-973, .page-template-medical-page, .page-id-1211 {
  .card-list-section {

    .title-block {
      margin-bottom: 40px;
    }

    .card-steps-list {

      .steps-card {
        padding: 40px 30px 45px 25px;

        .card-description {
          margin-bottom: 40px;
        }

        .image-wrapper {

          img {
            object-fit: cover;
            max-width: 469px;
            width: 100%;
            position: absolute;
            top: 0;
            right: 25px;
            height: initial;
          }
        }


        &:nth-of-type(1) {

          .image-wrapper {

            img {
              max-width: 276px;
              bottom: 0;
              top: unset;
              right: 10%;
            }
          }
        }

        &:nth-of-type(2) {

          .image-wrapper {

            img {
              max-width: 320px;
              top: -13px;
              right: 112px;
            }
          }
        }
      }

    }
  }
}

@media (min-width: 769px) {
  .card-list-section  {
    .card-steps-list .steps-card .card-content {
      height: 100%;
    }
  }

}

@media (max-width: 1024px) {

  .card-list-section {
    margin: 100px 0;

    .card-steps-list {

      .steps-card {

        .card-content {
          padding-right: 10px;
        }

        .image-wrapper {

          img {
            max-width: 55%;
            height: 300px;
          }
        }
        .image-wrapper-pc {
          display: none;
        }
        .image-wrapper-tablet {
          display: flex;
        }
      }
    }
  }

  .page-template-index, .page-id-973, .page-template-medical-page, .page-id-1211 {
    .card-list-section {


      .card-steps-list {

        .steps-card {

          .image-wrapper {

            img {
              right: 0;
              max-width: 382px;
            }
          }

          .image-wrapper-pc {
            display: none;
          }
          .image-wrapper-tablet {
            display: flex;
          }

          &:nth-of-type(1) {

            .image-wrapper {

              img {
                max-width: 236px;
              }
            }
          }

          &:nth-of-type(2) {
            .image-wrapper {

              img {
                right: 0;
                max-width: 320px;
                top: unset;
                bottom: 0;
              }
            }
          }

        }
      }

    }
  }

  .card-list-section-half {
    margin: 100px 0;

    .image-wrapper {
      margin-top: 20px;
    }

    .card-steps-list {

      .steps-card {
        padding: 25px;
      }
    }

  }

}

@media (max-width: 768px) {

  .card-list-section {
    margin: 60px 0;

    .card-steps-list {

      .steps-card {
        height: initial;
        padding: 0;
        margin-bottom: 20px;

        .card-content {
          padding: 30px 20px 0;
          margin-bottom: 20px;
        }

        .image-wrapper {

          img {
            max-width: 100%;
            position: initial;
            height: initial;
            min-height: 306px;
            border-radius: 30px;
          }
        }
        .image-wrapper-tablet {
          display: none;
        }
        .image-wrapper-mobile {
          display: flex;
        }
      }
    }
  }

  .page-template-index, .page-id-973, .page-template-medical-page, .page-id-1211 {
    .card-list-section {

      .card-steps-list {

        .steps-card {
          height: initial;
          padding: 0;

          .card-content {

            .link-purple {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              line-height: normal;
              letter-spacing: 0.175px;
              border-radius: 500px;
              background-color: $--color-purple;
              color: $--color-white;
              margin-bottom: 20px;
            }
          }

          .card-description {
            margin-bottom: 15px;
          }

          .image-wrapper {
            justify-content: center;

            img {
              position: static;
              border-radius: 0;
            }
          }

          .image-wrapper-tablet {
            display: none;
          }
          .image-wrapper-mobile {
            display: flex;
          }

        }
      }
    }
  }



  .card-list-section-half {
    margin: 60px 0;

    .description {
      margin-bottom: 10px;
    }

    .card-steps-list {

      .steps-card {
        height: initial;


        .card-content {
          padding-bottom: 25px;
        }
      }
    }

    .title-block {
      margin-bottom: 10px;
    }
  }

}
