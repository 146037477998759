.mini-calc-section {
  background-image: url(../img/calc-back.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto;
  position: relative;
  height: 470px;
  margin: 187px 0 130px;

  .description-small-tablet {
    display: none;
  }

  .description-small {

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.004em;
      color: $--color-grey;
      max-width: 425px;
    }

  }

  .container {
    height: inherit;
  }

  .calc-content {
    height: 100%;
    padding-right: 20px;

    .percent {
      font-size: 120px;
      font-weight: 600;
      line-height: 153px;
      color: $--color-default;
      display: flex;

      sup {
        font-size: 25px;
        font-weight: 400;
        line-height: 28px;
        color: $--color-purple;
      }

    }

    .description {
      margin-bottom: 22px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: $--color-default;
        max-width: 90%;

        span {
          color: $--color-purple;
        }
      }
    }

  }

  .image-wrapper {

    img {
      max-width: 313px;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: -122px;
      left: calc(50% - 180px);
    }

  }

  .image-wrapper-tablet {
    display: none;
  }

  .image-wrapper-mobile {
    display: none;
  }


  .mini-calc {
    background-color: $--color-grey-light;
    border-radius: 30px;
    padding: 40px;
    margin-left: auto;
    max-width: 527px;


    .input-wrapper {
      margin: 17px 0;
      height: 21px;
      position: relative;
      z-index: 2;

      input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 5px;
        background: $--color-purple-extra-light;
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
        border-radius: 10px;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          background: $--color-yellow;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          background: #000;
          cursor: pointer;
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 31px;
      color: $--color-default;
      max-width: 40%;
    }

    .info-number {
      font-size: 24px;
      font-weight: 300;
      line-height: 31px;
      color: $--color-grey;
      span {
        font-size: 66px;
        font-weight: 600;
        line-height: 84px;
        color: $--color-default;
        margin-right: 5px;
      }
    }

  }

}

@media (max-width: 1182px) {
  .mini-calc-section .calc-content .percent {
    font-size: 100px;
  }
}

@media (max-width: 1024px) {
  .mini-calc-section {
    margin: 100px 0;
    height: initial;
    background-image: url(../img/bg-calc-tab.png);

    .description-small-pc {
      display: none;
    }

    .description-small-tablet {
      display: block;
      margin-top: 15px;

      p {
        max-width: 100%;
      }
    }

    .col-md-6:nth-of-type(1) {
      padding-right: 0;
    }

    .image-wrapper {

      img {

        &:nth-of-type(1) {
          max-width: 120px;
          left: calc(50% - 70px);
          top: 20px;
          z-index: 1;
        }
        &:nth-of-type(2) {
          max-width: 150px;
          top: -20px;
          left: calc(50% - 90px);
          z-index: 0;
        }
      }
    }

    .image-wrapper-tablet {
      display: block;
    }

    .image-wrapper-pc {
      display: none;
    }

    .calc-content {
      padding-right: 0;
      padding-top: 30px;

      .percent {
        font-size: 94px;
        line-height: normal;
      }
    }
    .mini-calc {
      padding: 40px 30px;
      position: relative;
      z-index: 0;
      width: 100%;
      max-width: 100%;

      .info-title {
        font-size: 18px;
        line-height: normal;
        font-weight: 500;
        max-width: 50%;
      }

      .info-number {
        font-size: 18px;
        line-height: normal;
        font-weight: 400;
        letter-spacing: 0.027px;

        span {
          font-size: 50px;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: 826px) {
  .mini-calc-section .calc-content .percent {
    font-size: 80px;
  }
}

@media (max-width: 768px) {
  .mini-calc-section {
    background-image: url(../img/mini-calc-back-mobile.png);
    background-position: 100% 10%;
    margin: 60px 0;

    .mini-calc {
      padding: 40px 25px;
    }

    .calc-content {

      .description {
        margin-bottom: 38px;

        p {
          max-width: 240px;
        }
      }

      .percent {
        font-size: 92px;
        z-index: 1;
        position: relative;
      }
    }

    .image-wrapper {

      img {

        &:nth-of-type(1) {
          left: unset;
          right: 30px;
          top: 110px;
        }

        &:nth-of-type(2) {
          right: 25px;
          left: unset;
          max-width: 100px;
          top: 60px;
        }
      }
    }

    .image-wrapper-mobile {
      display: block;
    }

    .image-wrapper-tablet {
      display: none;
    }

  }
}

@media (max-width: 372px) {
  .mini-calc-section .calc-content .percent {
    font-size: 82px;
  }
}
