.image-banner-section {
  margin: 175px 0 60px;

  .image-wrapper-mobile {
    display: none;
  }

  .title-block {
    margin-bottom: 0;
  }

  .image-banner {
    width: 100%;
    border-radius: 30px;

    .image-wrapper {
      margin-top: -75px;

      img {
        height: 483px;
        width: 100%;
        object-fit: cover;
      }
    }

    .image-wrapper-tablet {
      display: none;
    }

  }

  .image-banner-content {
    padding: 77px 0 79px 43px;
    z-index: 1;
    position: relative;

    .h6 {
      color: $--color-grey;
    }

    .image-banner-title {
      font-size: 90px;
      font-weight: 400;
      line-height: 115px;
      color: $--color-default;
    }

    .image-banner-subtitle {
      font-size: 35px;
      font-weight: 300;
      line-height: 45px;
      color: $--color-default;
      margin-bottom: 35px;
    }

    .image-banner-links {
      display: flex;
      align-items: center;
      width: 100%;

      .link-black {
        margin-left: 50px;
      }
    }
  }

}

.image-banner-section-second {
  position: relative;
  margin: 232px 0 170px;
  width: 100%;

  .banner-description {
    margin-bottom: 50px;
  }

  .image-banner-content {
    padding: 60px 0 60px 40px;
  }

  h2 {
    margin-bottom: 10px;
  }

  .image-banner {
    background-color: $--color-grey-light;
    position: relative;
    background-image: url(../img/banner-back3.png);
    background-repeat: no-repeat;
    background-position: right;

    .image-wrapper {

      img {
        position: absolute;
        height: 403px;
        width: initial;
        bottom: 0;
        right: 140px;
        z-index: 0;
      }
    }

  }
}

.image-banner-section-third {
  margin: 175px 0 80px;


  .image-banner {
    position: relative;
    border-radius: 30px;
    background-image: url(../img/back-banner-img.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;

    .image-banner-content {
      padding: 60px 0 60px 40px;
    }

    h2 {
      margin-bottom: 10px;
      max-width: 80%;
    }

    .banner-description {
      margin-bottom: 34px;
    }

    .image-wrapper {

      img {
        position: absolute;
        bottom: 0;
        height: initial;
        border-radius: 30px;
        max-width: 55%;
        right: 0;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.page-template-index, .page-id-973, .single-locations {

  .image-banner-section-third {

    .image-wrapper {

      img {
        object-fit: contain;
        height: 443px;
      }
    }
  }
}

.image-banner-section-drug {

  .image-banner {

    .image-wrapper {
      display: flex;

      img {
        height: initial;
        max-width: 410px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .image-banner-section {
    margin: 100px 0;

    .image-banner {

      .image-wrapper  {
        margin-top: 0;
        height: 100%;
        align-items: flex-end;
        padding-bottom: 20px;

        img {
          height: initial;
        }
      }

      .image-wrapper-tablet {
        display: block;
      }

    }

    .image-banner-content  {
      padding: 75px 0 75px 30px;

      .image-banner-links {

        .link-black {
          margin-left: 30px;
        }
      }

      .image-banner-title {
        font-size: 80px;
        line-height: normal;
      }
      .image-banner-subtitle {
        font-size: 25px;
        margin-top: -20px;
        margin-bottom: 40px;
      }
    }
  }
  .image-banner-section-second {

    .image-banner-content {
      padding: 40px 20px 40px 40px;
    }

    .image-banner {
      background-image: url(../img/back321.png);

      .image-wrapper {

        img {
          right: 18px;
          height: 370px;
        }
      }
      .image-wrapper-pc {
        display: none;
      }
    }
  }

  .image-banner-section-third {
    margin: 100px 0;

    .image-banner {
      background-image: url(../img/back2f4.png);
      background-size: initial;

      .image-banner-content {
        padding: 40px 0 40px 25px;
      }

      h2 {
        max-width: 100%;
      }

      .banner-description {

        p {
          max-width: 100%;
        }
      }

      .image-wrapper {

        img {
          right: 0;
          height: initial;
          max-width: 365px;
        }
      }
      .image-wrapper-pc {
        display: none;
      }
    }
  }

}


@media (max-width: 768px) {
  .image-banner-section {
    margin: 60px 0;

    .image-banner {
      .image-wrapper {

        img {
          height: initial;
          max-width: 287px;
        }
      }
      .image-wrapper-mobile {
        display: flex;
        justify-content: center;
      }
    }

    .image-banner-content {
      padding: 35px 20px 30px;

      .image-banner-title {
        font-size: 58px;
        line-height: normal;
      }

      .image-banner-subtitle {
        margin-top: 0;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.027px;
      }

      .image-banner-links {
        flex-wrap: wrap;
        justify-content: center;

        .btn-black {
          max-width: 100%;
        }

        .link-black {
          margin-left: 0;
          margin-top: 20px;
        }
      }

    }

    .image-wrapper-mobile {
      display: block;
    }
    .image-wrapper-pc {
      display: none;
    }
  }

  .image-banner-section-second {
    margin: 55px 0 80px;

    .image-banner {
      background-image: url(../img/back5451.png);
      background-position: 50% 100%;
      background-size: 100% 50%;

      .image-wrapper-mobile {
        display: flex;
        justify-content: center;
        padding: 30px 0 0 0;

        img {
          position: static;
          height: initial;
        }
      }

      .image-wrapper-tablet {
        display: none;
      }
    }

    .banner-description {
      margin-bottom: 20px;
    }

    .image-banner-content {
      padding: 30px 20px 0;
    }
  }

  .image-banner-section-third {

    .title-block {
      h2 {
        font-size: 30px;
        line-height: 31px;
      }
    }

    .image-banner {
      background-image: url(../img/back691.png);
      background-position: 50% 100%;
      background-size: 100% 50%;


      .image-banner-content {
        padding: 30px 20px 0;
      }

      .image-wrapper {

        img {
          position: static;
          height: initial;
          max-width: 100%;
        }
      }

      .image-wrapper-mobile {
        display: flex;
        padding-bottom: 0;
        padding-top: 30px;
      }
      .image-wrapper-tablet {
        display: none;
      }
    }
  }

}

