.single-location-section {
  margin: 40px 0 140px;

  .map-wrapper {
    width: 100%;
    height: 263px;

    iframe {
      height: inherit;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .location-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title-head {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: space-between;

      .location-state {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.175px;
        color: $--color-purple;
        margin-bottom: 8px;
      }
    }

    .location-contacts {

      .contact-link {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: $--color-grey;
          margin-left: 10px;
        }

        a, div {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: $--color-default;
          display: flex;
          align-items: center;
          width: fit-content;


          &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            margin-right: 10px;
          }
        }

      }

      .contact-link-address {

        div:before {
          background-image: url(../img/icons/ico-address-purple.svg);
        }
      }

      .contact-link-phone {
        a:before {
          background-image: url(../img/icons/ico-phone-purple.svg);
        }
      }

      .contact-link-mail {
        a:before {
          background-image: url(../img/icons/ico-mail-purple.svg);
        }
      }

    }

    .location-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      .btn {
        border-radius: 500px;

        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px;
          margin-right: 10px;
        }
      }

      .location-btn-back {
        border: 1px solid $--color-grey;
        color: $--color-default;
        max-width: calc(50% - 15px);

        &:before {
          background-image: url(../img/icons/arrow-black-loc.svg);
          transition: 0.3s;
        }

        &:hover {
          background-color: $--color-default;
          color: $--color-white;

          &:before {
            background-image: url(../img/icons/arrow-white-loc.svg);
          }
        }
      }


      .location-btn-nav {
        max-width: calc(50% - 15px);

        &:before {
          background-image: url(../img/icons/nav-ico-loc.svg);
        }
      }

    }

  }

}