.staff-section {
  margin: 40px 0 175px;

  .title-head {
    margin-bottom: 30px;
  }

  .block-tablet, .block-mobile {
    display: none;
  }

  .block-pc {

    .staff-card {

      .staff-link {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        height: 0;
        margin-top: 0;
      }

      &:hover {
        background-color: $--color-purple;

        .staff-name {
          color: $--color-white;
        }

        .staff-link {
          visibility: visible;
          overflow: visible;
          opacity: 1;
          height: initial;
          margin-top: 30px;
          color: $--color-white;
        }

        .staff-description p {
          color: $--color-purple-extra-light;
        }


      }
    }
  }

  .staff-card {
    border-radius: 30px;
    background-color: $--color-grey-light;
    padding: 25px;
    transition: all 0.3s;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    .staff-name {
      margin-bottom: 4px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.016px;
      color: $--color-default;
      transition: 0.3s;
    }

    .staff-description {
      text-align: center;
      transition: 0.3s;
      max-height: 40px;
      overflow: hidden;

      P {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: $--color-grey;
        letter-spacing: 0.048px;
        transition: 0.3s;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        box-orient: vertical;
      }
    }

    .staff-image {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      img {
        width: 117px;
        height: 117px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .staff-link {
      display: block;
      color: $--color-purple;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.175px;
      margin-top: 30px;
    }



  }
}

@media (max-width: 1024px) {
  .staff-section {
    margin: 40px 0 100px;

    .staff-card {

      .staff-description {

        p {
          -webkit-line-clamp: 3;
          line-clamp: 3;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    .block-pc {
      display: none;
    }

    .block-tablet {
      display: flex;
    }
  }
}

@media (max-width: 670px) {
  .staff-section {
    margin: 20px 0 60px;

    .title-head {
      margin-bottom: 16px;
    }

    .staff-card {
      display: flex;
      padding: 20px;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      .staff-description {
        max-height: 60px;
        overflow: hidden;

        p {
          -webkit-line-clamp: 2;
          line-clamp: 2;
        }
      }

      .staff-image {
        width: 30%;
        margin-right: 15px;
        margin-bottom: 0;

        img {
          width: 80px;
          height: 80px;
          min-width: 80px;
        }
      }
      .staff-content {
        width: 70%;
      }
      .staff-link {
        margin-top: 10px;
      }
    }

    .block-tablet {
      display: none;
    }
    .block-mobile {
      display: flex;
    }
  }
}
