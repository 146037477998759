.main-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  li {
    position: relative;
    padding: 15px 0;

    &:hover {

      > a {
        color: $--color-purple-dark;
      }
    }

    a {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.001em;
      color: $--color-default;

      &:focus {
        color: $--color-purple;
      }
    }

    .sub-menu {
      position: absolute;
      width: 320px;
      background: $--color-grey-light;
      border-radius: 30px;
      list-style: none;
      padding: 30px;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      pointer-events: none;
      transition: 0.3s;
      box-shadow: 0 0 0 2px #0000000d;
      top: 45px;
      z-index: 5;

      li {
        padding: 0;
      }

      li + li {
        margin-left: 0;
        margin-top: 10px;
      }

    }

  }

  .menu-item-has-children {

    &:after {
      content: "";
      border: solid $--color-default;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      transform: rotate(45deg);
      transition: 0.3s;
      margin-left: 3px;
      position: relative;
      top: -2px;
    }

    &:hover {

      &:after {
        transform: rotate(225deg);
        border-color: $--color-purple-dark;
        top: 0;
      }

      .sub-menu {
        visibility: visible;
        overflow: visible;
        pointer-events: auto;
        opacity: 1;
      }

    }

  }

  li + li {
    margin-left: 40px;
  }

}


@media (max-width: 1024px) {
  .main-menu {
    display: block;

    li {
      width: 100%;
      text-align: right;
      padding: 0;
    }

    .menu-item-has-children {

      .sub-menu {
        opacity: 1;
        visibility: visible;
        overflow: visible;
        position: initial;
        pointer-events: auto;
        padding: 0;
        background: transparent;
        box-shadow: none;
        width: initial;
        margin-top: 10px;

        li {

          a {
            font-size: 14px;
            line-height: 17px;
          }
        }

        li + li {
          margin-top: 5px;
        }

      }

      &:after {
        display: none;
      }

    }

    li + li {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

@media (max-width: 600px) {
  .header {

    .header-nav {
      width: calc(100% - 48px);
      left: 24px;
      right: unset;
    }
  }
}