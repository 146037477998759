.locations-section {
  margin: 40px 0 100px;

  .title-head {
    display: flex;
    align-items: flex-start;

    .locations-total {
      color: $--color-purple;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.175px;
      margin-left: 2px;
    }
  }

  .right-bar-wrapper {
    display: flex;
    justify-content: flex-end;
    .search-wrapper {
      max-width: 470px;
      width: 100%;
      margin-right: 30px;
      position: relative;

      input[type="search"] {
        width: 100%;
        height: 40px;
        background-color: $--color-grey-light;
        border-radius: 500px;
        padding: 0 20px;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.175px;
        color: $--color-grey;

        &::placeholder {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.175px;
          color: $--color-grey;
        }
      }

      .search-btn {
        background-image: url(../img/icons/search-ico.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        background-color: transparent;
        position: absolute;
        right: 20px;
        top: calc(50% - 8px);
      }
    }

    .btn-locations {
      cursor: pointer;

      &:after {
        content: "";
        display: block;
        background-image: url(../img/icons/drop-down-ico.svg);
        width: 16px;
        height: 16px;
        margin-left: 10px;
        transition: 0.3s;
      }

      &:hover {
        color: $--color-white;
      }

    }
    .btn-locations.rotate {

      &:after {
        transform: scale(-1);
      }
    }
  }

  .link-view-all {
    color: $--color-default;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.175px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      background-image: url(../img/icons/ico-lick-view-all.svg);
      margin-left: 10px;
    }
  }

  .link-view-all-pc {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }

  .link-view-all-tablet {
    display: none;
  }

  .locations-list {
    margin: 30px 0;
    width: 100%;
    transition: 0.3s;
    max-height: 2000px;
    position: relative;

    ul {
      display: block;
      column-count: 6;
      list-style: none;
      margin: 0;
      padding: 30px;
      background-color: $--color-grey-light;
      border-radius: 20px;

      li {
        margin-bottom: 10px;
        color: $--color-default;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.027px;
        text-transform: capitalize;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          color: $--color-purple;
        }
      }

      .current {
        color: $--color-purple;
      }

    }
  }

  .locations-list.hidden {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    margin: 15px 0;
  }

  .location-card {
    display: flex;
    padding: 20px;
    background-color: $--color-grey-light;
    border-radius: 20px;
    transition: 0.3s;
    margin-bottom: 30px;

    * {
      transition: 0.3s;
    }

    .location-map {
      width: 170px;
      height: 170px;
      margin-right: 30px;

      iframe {
        width: 100%;
        height: inherit;
        object-fit: cover;
      }
    }

    .location-content {
      width: 70%;
      display: flex;
      flex-direction: column;

      .location-name {
        color: $--color-default;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .location-state {
          color: $--color-purple;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.175px;
          margin-top: 5px;
        }
      }

      .location-address {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.08px;
        color: $--color-default;
      }

      .location-links {
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        .link-purple {
          display: flex;
          align-items: center;

          &:before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            width: 16px;
            height: 16px;
            background-image: url(../img/icons/phone-ico-purple.svg);
            margin-right: 10px;
          }
        }

        .link-black {
          display: flex;
          align-items: center;

          &:after {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            width: 16px;
            height: 16px;
            background-image: url(../img/icons/arrow-black.svg);
            margin-left: 10px;
          }
        }

      }

    }

    &:hover {
      background-color: $--color-purple-dark;

      .location-content {

        .location-name {
          color: $--color-white;

          .location-state {
            color: $--color-purple-light;
          }
        }
      }
      .location-address {
        color: $--color-white;
      }

      .location-links {

        .link-purple {
          color: $--color-white;

          &:before {
            background-image: url(../img/icons/phone-ico-white.svg);
          }
        }

        .link-black {
          color: $--color-white;

          &:after {
            background-image: url(../img/icons/arrow-white.svg);
          }
        }
      }
    }

  }

  .btn-locations-more {
    border-radius: 500px;
    border: 1px solid $--color-grey;
    max-width: 335px;
    width: 100%;
    cursor: pointer;
    margin: 20px auto 0;

    &:hover {
      background-color: $--color-default;
      color: $--color-white;
    }
  }

}

@media (max-width: 1024px) {
  .locations-section {
    margin: 40px 0 60px;

    .title-head {
      margin-bottom: 20px;
    }

    .right-bar-wrapper {
      justify-content: flex-start;

      .btn-locations {
        max-width: 170px;
        margin-left: 20px;
      }

      .search-wrapper {
        max-width: 45%;
        margin-right: 24px;
      }
    }

    .link-view-all-tablet {
      display: flex;
    }

    .link-view-all-pc {
      display: none;
    }

    .locations-list {
      margin: 24px 0 95px;

      ul {
        column-count: 4;
      }
    }

    .location-card {
      flex-wrap: wrap;
      margin-bottom: 24px;

      .location-map {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
      }

      .location-content {
        width: 100%;

        .location-address {
          margin-bottom: 30px;
        }
      }

    }

    .btn-locations-more {
      margin-top: 6px;
    }

  }
}

@media (max-width: 768px) {
  .locations-section {

    .right-bar-wrapper {
      flex-wrap: wrap;

      .search-wrapper {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
      .link-view-all-tablet {
        width: 100%;
        height: 40px;
        border-radius: 500px;
        justify-content: center;
        border: 1px solid $--color-grey;
        margin-bottom: 10px;
      }
      .btn-locations {
        max-width: 100%;
        margin-left: 0;
      }
    }
    .locations-list {
      margin: 10px 0 24px;

      ul {
        column-count: 2;
      }
    }
  }
}

@media (max-width: 360px) {
  .locations-section .location-card .location-content .location-name {
    font-size: 20px;
  }
}