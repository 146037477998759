.treatments-section {
  margin: 130px 0 125px;
}

@media (max-width: 1024px) {
  .treatments-section {
    margin: 100px 0;

    .btn {
      background-color: $--color-purple;
    }
  }
}

@media (max-width: 768px) {
  .treatments-section {
    margin: 80px 0;
  }
}
