form.invalid {
  .wpcf7-response-output {
    border-color: transparent!important;
    color: #dc3232;
    border-radius: 20px;
    padding: 0!important;
  }
}

form.sent .wpcf7-response-output {
  color: #46b450;
  border-radius: 20px;
  border-color: transparent!important;
  padding: 0!important;
}

form {
  .wpcf7-spinner {
    display: none;
  }
}

.form-wrapper {

  form {

    div {

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.048px;
        color: $--color-grey;
      }
    }

    > p {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;

      span {
        width: 50%;
        padding: 0 15px;
        margin-bottom: 20px;
      }

      .wpcf7-not-valid-tip {
        width: 100%;
        margin-bottom: 0;
      }

      span:has(textarea) {
        width: 100%;
      }
    }

    p:has(input[type='submit']) {
      margin: 0;
    }

    textarea {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.175px;
      color: $--color-grey;
      height: 155px;
      resize: none;
      border-radius: 15px;
      background-color: $--color-grey-light;
      outline: none;
      box-shadow: none;
      border: 0;
      padding: 20px;
      width: 100%;
      max-width: 100%;

      &::placeholder {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.175px;
        color: $--color-grey;
      }
    }

    input {
      border-radius: 500px;
      background-color: $--color-grey-light;
      border: 0;
      outline: none;
      box-shadow: none;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.175px;
      color: $--color-grey;
      padding: 0 20px;
      max-width: 100%;
      width: 100%;

      &::placeholder {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.175px;
        color: $--color-grey;
      }
    }

    input[type='submit'] {
      border-radius: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 270px;
      width: 100%;
      height: 40px;
      color: $--color-white;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.28px;
      background-color: $--color-purple;
      margin-top: 13px;
      margin-left: auto;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: $--color-purple-dark;
      }
      &:focus {
        background-color: $--color-purple-light;
      }
    }

  }
}

@media (max-width: 1024px) {
  .form-wrapper {

    form {

    > p {
      margin: 0 -12px;

        span {
          padding: 0 12px;
        }
      }

     input[type=submit] {
       margin-top: 30px;
     }

    }
  }
}

@media (max-width: 600px) {
  .form-wrapper {

    form {

      > p {


        span {
          width: 100%;
          margin-bottom: 15px;

          &:nth-of-type(1) {
            order: -1;
          }

          &:nth-of-type(3) {
            order: -1;
          }
        }
      }

      input[type=submit] {
        margin-top: 20px;
        max-width: 100%;
      }

    }
  }
}