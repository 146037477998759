.before-after-section {
  margin: 150px 0;
  position: relative;
  overflow: hidden;

  .swiper-slide {
    opacity: 0;
    transition: 0.3s;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .swiper-button-next, .swiper-button-prev {
    bottom: unset;
    top: 35px;
  }

  .content-tablet {
    display: none;
  }

  .description-mobile {
    display: none;
  }

  .before-block-wrapper {
    padding: 30px 40px 40px 30px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 85px);

    .images-wrapper {
      display: flex;

      img {
        height: 515px;
        object-fit: cover;

        &:nth-last-of-type(1) {
          width: 198px;
        }
        &:nth-of-type(2) {
          width: 232px;
        }
      }
    }

    .h6 {
      margin-bottom: 20px;
      font-weight: 500;
    }
    .description {
      margin-bottom: 55px;

      p {
        font-weight: 400;
      }
    }
  }

  .drug-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 61%;
    padding-left: 30px;
  }

  .picked-drug {
    padding: 13px 30px 30px;
    background-color: $--color-white;
    border-radius: 30px;
    position: relative;
    margin-top: auto;

    .h3 {
      margin-bottom: 10px;
    }

    .results-list {
      max-width: 305px;
      width: 100%;
      margin-bottom: 28px;

      .result-item {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &:not(:last-of-type) {
          margin-bottom: 11px;
        }

        div {
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.04px;
          color: $--color-default;

          &:nth-of-type(2) {
            font-weight: 400;
          }
        }
      }
    }

    .btn {

    }

    .drug-image {

      img {
        position: absolute;
        right: 50px;
        bottom: 0;
        max-width: 128px;
        object-fit: cover;
        height: 263px;
      }
    }

    .drug-image-mobile {
      display: none;
    }

  }

}

.sl-container {
  overflow: hidden;
  position: relative;
  width: 39%;
  height: 515px;
}
.sl-container .view {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.sl-container .view img {
  width: 430px;
  max-width: 430px;
  height: 515px;
  border-radius: 30px;
  object-fit: cover;
}
.sl-container .view-before {
  z-index: 100;
}
.sl-container .view-after {
  width: 100px;
  z-index: 200;
}
.sl-container .dragme {
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  left: 100px;
  background-color: $--color-purple;
  cursor: pointer;
  z-index: 300;
}
.sl-container .dr-circle {
  position: absolute;
  top: calc(50% - 20px);
  left: -15px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  text-align: center;
  background-color: $--color-yellow;
  cursor: pointer;
  z-index: 300;
}
.sl-container .dr-circle i {
  line-height: 40px;
  font-size: 20px;
  color: #337AB7;
  font-weight: bold;
}

@media (max-width: 1024px) {

  .before-after-section {

    .before-block-wrapper {
      flex-wrap: wrap;
      width: 100%;
      height: initial;
      padding: 35px 25px;

      .h6 {
        font-size: 18px;
      }
    }

    .drug-content-wrapper {
      width: 100%;
      margin-top: 75px;
      padding-left: 0;
    }

    .content-tablet {
      display: block;
      width: 50%;
      order: -1;
      padding-right: 8px;
    }

    .tablet-hidden {
      display: none;
    }
  }

  .sl-container {
    width: 50%;
    height: 373px;

    .view img {
      width: initial;
      height: 373px;
    }
  }

}


@media (max-width: 550px) {
  .before-after-section {
    margin: 60px 0;

    .sl-container {
      width: 100%;
      height: 345px;

      .view img {
        height: 345px;
      }
    }

    .content-tablet {
      padding-right: 0;
      width: 100%;

      .description {
        display: none;
      }
    }

    .picked-drug {
      padding: 0;
      background-color: transparent;

      .h3 {
        padding-left: 110px;
        width: 100%;
        margin-bottom: 15px;
      }

      .results-list {
        padding-left: 111px;
        margin-bottom: 30px;

        .result-item {
          flex-wrap: wrap;

          div {
            width: 100%;
            color: $--color-grey;

            &:nth-of-type(2) {
              color: $--color-default;
            }
          }
        }
      }

      .btn {
        max-width: 100%;
        justify-content: flex-end;
        padding-right: 18px;
      }

      .drug-image {

        img {
          left: 0;
          right: unset;
          max-width: 100px;
          bottom: -30px;
          height: initial;
        }
      }
      .drug-image-pc {
        display: none;
      }
      .drug-image-mobile {
        display: block;
      }
    }

    .before-block-wrapper {
      padding: 30px 20px 55px;
      .description-mobile {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

  }

  .postid-538 .before-after-section .picked-drug .drug-image img {
    left: -30px;
  }

}
