
.container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1218px;
  width: 100%;
  padding: 0 24px;
}

.container .container {
  padding: 0;
}

.container-fluid {
  max-width: 100%;
  width: 100%;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.row {
  display: flex;
  margin: 0 -15px;
  width: calc(100% + 30px);
  flex-wrap: wrap;
}

[class*="col-"] {
  padding: 0 15px;
}


@media (min-width: 1024px) {
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-11 {
    width: 91.66666667%
  }
  .col-lg-12 {
    width: 100%;
  }
}


@media (max-width: 1023px) {
  .container {
    padding: 0 70px;
  }
  .row {
    margin: 0 -12px;
    width: calc(100% + 24px);
  }
  [class*=col-] {
    padding: 0 12px;
  }
  .col-md-0 {
    width: 0;
    padding: 0;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-11 {
    width: 91.66666667%
  }
  .col-md-12 {
    width: 100%;
  }
  .t-d-none {
    display: none;
  }
}

@media (max-width: 768px) {
  .col-m-1 {
    width: 8.33333333%;
  }
  .col-m-2 {
    width: 16.66666667%;
  }
  .col-m-3 {
    width: 25%;
  }
  .col-m-4 {
    width: 33.33333333%;
  }
  .col-m-5 {
    width: 41.66666667%;
  }
  .col-m-6 {
    width: 50%;
  }
  .col-m-7 {
    width: 58.33333333%;
  }
  .col-m-8 {
    width: 66.66666667%;
  }
  .col-m-9 {
    width: 75%;
  }
  .col-m-10 {
    width: 83.33333333%;
  }
  .col-m-11 {
    width: 91.66666667%
  }
  .col-m-12 {
    width: 100%;
  }
  .container {
    padding: 0 24px;
  }
}

