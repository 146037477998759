.faq-section {
  margin: 140px 0 100px;

  .callback-card {
    padding: 94px 50px 60px;
    height: 493px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;

    .title-block {
      margin-bottom: 0;
    }

    .h5 {
      margin-bottom: 5px;
      font-weight: 400;
    }

    .description {
      width: 100%;

      p {
        margin-bottom: 27px;
        color: $--color-grey;
        max-width: 90%;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

    .btn {
      max-width: 100%;
    }

  }
}

@media (max-width: 1024px) {

  .faq-section {
    margin: 100px 0 60px;

    .title-block h2 {
      max-width: 70%;
    }

    .callback-card {
      margin-top: 24px;
      padding: 50px 40px;
      flex-direction: row;
      height: initial;

      .content-top {
        width: 50%;
      }

      .content-bottom {
        width: 50%;
      }

      .description {

        p {
          max-width: 100%;
          margin-bottom: 20px;
        }

        p:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
    }

    .col-md-12:nth-of-type(2) {
      order: -1;
    }
  }
}

@media (max-width: 768px) {
  .faq-section {
    margin: 80px 0;

    .callback-card {
      flex-direction: column;
      padding: 30px 20px;

      .description {
        p {
          letter-spacing: 0.08px;
          display: inline;
        }
      }

      .btn-black {
        margin-top: 20px;
      }

      .title-block {
        margin-bottom: 12px;

        h2 {
          font-size: 40px;
          line-height: 105%;
          letter-spacing: 0.4px;
        }
      }

      .content-top, .content-bottom {
        width: 100%;
      }
    }
  }
}
