//
// Project Variables
//

/* fonts */
$--font-default: 'Onest';
$--font-second: 'Radio Canada';
$--font-third: 'myriad-pro';

/* colors */
$--color-default: #202020;
$--color-white: #fff;
$--color-purple: #A970AF;
$--color-purple-dark: #905796;
$--color-purple-light: #C39BC7;
$--color-purple-extra-light: #E7DBE8;
$--color-yellow: #F8C82E;
$--color-yellow-light: #FBE396;
$--color-grey: #7D7D7D;
$--color-grey-opacity: #7d7d7d4d;
$--color-grey-light: #F8F8F8;
