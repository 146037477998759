.contact-form-section {
  margin: 40px 0 110px;

  .contact-form-info {
    padding: 95px 60px 50px;
    background-color: $--color-grey-light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 482px;
    border-radius: 20px;


    .content-bottom {

      .content-description {
        margin-bottom: 45px;

        p {
          color: $--color-grey;
        }
      }

      .btn-black {
        max-width: 100%;
      }
    }
  }

}

.contact-section {
  margin: 110px 0 100px;

  .title-head {
    margin-bottom: 30px;
  }

  .contact-card {
    padding: 30px;
    border-radius: 20px;
    background-color: $--color-grey-light;
    height: 100%;

    .h5 {
      margin-bottom: 20px;
    }

    .contact-item {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.08px;
      color: $--color-default;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        min-width: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        margin-right: 10px;
      }
    }

    a.contact-item:hover {
      color: $--color-purple;
    }

    .contact-item-phone {

      &:before {
        background-image: url(../img/icons/icon-phone.svg);
      }
    }

    .contact-item-mail {

      &:before {
        background-image: url(../img/icons/icon-mail.svg);
        margin-top: 3px;
      }
    }

    .contact-item-address {

      &:before {
        background-image: url(../img/icons/icon-address.svg);
      }
    }

    .contact-item-time {

      &:before {
        background-image: url(../img/icons/icon-time.svg);
      }

      .contact-time-wrapper {
        display: flex;
        flex-wrap: wrap;

        .work-time {
          display: flex;
          width: 100%;
          justify-content: space-between;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }

          .work-day {
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.08px;
            color: $--color-default;
            width: 50%;
          }

          .work-time {
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.08px;
            color: $--color-purple;
            width: 50%;
          }
        }
      }

    }

  }

  .contact-map-wrapper {
    margin-top: 30px;
    height: 263px;
    width: 100%;

    iframe {
      width: 100%;
      height: inherit;
      object-fit: cover;
      border-radius: 20px;
    }
  }

}

@media (max-width: 1024px) {

  .contact-form-section {
    margin: 30px 0 120px;

    .contact-form-info {
      height: initial;
      flex-direction: row;
      padding: 40px;
      margin-bottom: 30px;

      .h2 {
        width: 50%;
      }

      .content-bottom {
        width: 50%;

        .content-description {
          margin-bottom: 15px;
        }
      }
    }
  }

  .contact-section {
    margin: 120px 0 80px;

    .card-third {
      order: 0;
    }

    .card-second {
      order: 1;
    }

    .card-first {
      order: 2;
      margin-top: 24px;

      .contact-card {
        height: 191px;
      }
    }

    .card-fourth {
      order: 4;
    }

    .contact-card {
      height: initial;
    }

    .contact-map-wrapper {
      margin-top: 24px;
      height: 191px;
    }

  }
}


@media (max-width: 768px) {
  .contact-form-section {
    margin: 20px 0 60px;

    .contact-form-info {
      flex-direction: column;
      padding: 30px 20px;
      margin-bottom: 40px;

      .h2 {
        width: 100%;
        margin-bottom: 12px;
      }

      .content-bottom {
        width: 100%;

        .content-description {
          margin-bottom: 20px;
        }
      }

    }
  }

  .contact-section {
    margin: 60px 0;

    .title-head {
      margin-bottom: 20px;
    }

    .card-third {
      margin-bottom: 24px;
    }

    .card-first {

      .contact-card {
        height: initial;
      }
    }

    .contact-card {
      padding: 30px 20px;

      .contact-item-time {

        .contact-time-wrapper  {

          .work-time {
            flex-direction: column;

            &:not(:last-of-type) {
              margin-bottom: 20px;
            }

            .work-time {
              width: 100%;
            }

            .work-day {
              width: 100%;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

  }

}