.author-post-section {
  margin: 150px 0;

  .author-card {
    display: flex;
    padding: 50px 0 40px 40px;
    position: relative;
    border-radius: 30px;

    .author-content {
      max-width: 220px;
      width: 100%;
    }

    .author-image {

      img {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .title-block {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 50px;
    }

    .h5 {
      color: $--color-purple;
      margin-bottom: 10px;
    }

    .author-description {
      margin-bottom: 30px;

      p {
        color: $--color-grey;
        font-size: 14px;
        font-weight: 300;
      }
    }

  }

  .resources-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 30px;

    .h5 {
      margin-bottom: 10px;
    }

    ol {
      padding-left: 20px;
      margin: 0;

      li {
        color: $--color-default;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.04px;
        word-break: break-all;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }

        a {
          color: $--color-default;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.04px;
        }
      }
    }
  }

}

@media (max-height: 1024px) {
  .author-post-section {
    margin: 100px 0;

    .author-card {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 768px) {
  .author-post-section {
    margin: 60px 0;

    .author-card {
      flex-wrap: wrap;
      padding: 30px 0 0;

      h2 {
        margin-bottom: 20px;
      }

      .author-description {
        margin-bottom: 20px;
      }

      .author-content {
        padding: 0 25px;
        max-width: 100%;
      }

      .author-image {
        margin-top: 20px;

        img {
          position: initial;
        }
      }
    }
  }
}
