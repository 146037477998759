.single-doctor-section {
  margin: 40px 0 80px;

  .content-column {
    padding-right: 130px;
  }

  .title-head {
    margin-bottom: 20px;
  }

  .title-block {
    margin-bottom: 0;
  }

  .doctor-content {
    margin-bottom: 80px;

    h2 {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 105%;
      letter-spacing: 0.3px;
      color: $--color-default;
      margin: 20px 0 10px;
    }

    ul, ol {
      padding-left: 20px;
      margin: 20px 0;

      li {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: .0025em;
        color: $--color-default;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

  }
  .doctor-image {

    img {
      max-width: 370px;
      width: 100%;
      height: 450px;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .doctor-image-mobile {
    display: none;
  }

  .doctor-education {
    margin-bottom: 80px;

    h2 {
      margin-bottom: 20px;
    }

    .education-item {



      &:not(:last-of-type) {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $--color-purple-extra-light;
      }

      .education-title {
        color: $--color-purple;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;

        .education-date {
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.08px;
          color: $--color-default;
          width: 25%;
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
        }
      }

      .education-description {

        p {
          font-size: 16px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0.04px;
          color: $--color-default;
          max-width: 90%;
        }
      }

    }
  }

  .doctor-certificates {
    margin: 80px 0 50px;

    h2 {
      margin-bottom: 30px;
    }

    .doctor-certificate {
      display: flex;
      align-items: flex-end;
      border-radius: 20px;
      min-height: 106px;
      height: calc(100% - 30px);
      padding: 30px 20px;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.027px;
      color: $--color-default;
      background: $--color-grey-light;
      margin-bottom: 30px;
    }
  }

  .doctor-documents {
    margin-bottom: 30px;
    width: 100%;

    h2 {
      margin-bottom: 30px;
      width: 100%;
    }

    .documents-images {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;

      img {
        margin-bottom: 30px;
        display: inline-block;
        max-width: calc(25% - 30px);
        margin-left: 30px;

        &:nth-child(1) {
          margin-left: 0;
        }

        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }
    }

  }

}

@media (max-width: 1024px) {
  .single-doctor-section {


    .content-column {
      padding-right: 12px;
    }

    .doctor-education {
      .education-item {
        .education-description {

          p {
            max-width: 87%;
          }
        }
      }
    }

    .doctor-certificates {

      .doctor-certificate {
        align-items: center;
      }
    }

    .doctor-documents {

      .documents-images {

        img {
          max-width: calc(50% - 24px);

          &:nth-child(2n + 1) {
            margin-left: 0;
          }
        }
      }
    }

  }
}

@media (max-width: 768px) {
  .single-doctor-section {
    margin: 20px 0 60px;

    .doctor-image {
      margin-bottom: 20px;

      img {
        max-width: 100%;
        height: 350px;
      }
    }

    .doctor-image-mobile {
      display: block;
    }

    .doctor-image-pc {
      display: none;
    }

    .doctor-content {
      margin-bottom: 60px;
    }

    .doctor-education {

      .education-item {

        .education-description {

          p {
            max-width: 100%;
          }
        }
      }
    }

    .doctor-education {
      margin-bottom: 60px;

      .education-item {

        .education-title {

          .education-date {
            width: 30%;
          }
        }
      }
    }

    .doctor-certificates {
      margin: 0 0 50px;

      h2 {
        margin-bottom: 20px;
      }

      .doctor-certificate {
        height: initial;
        padding: 20px;
        margin-bottom: 10px;
      }

    }

  }
}