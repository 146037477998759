.accordion {
  width: 100%;

  .accordion-item {
    transition: all 0.3s;
    width: 100%;
    overflow: hidden;

    .accordion-item-header {
      border-radius: 50px;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      color: $--color-default;
      margin-bottom: 0;
      transition: all ease 0.3s;
      padding: 20px 30px;
      justify-content: space-between;
      background-color: $--color-grey-light;

      &:after {
        transition: 0.3s;
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        min-width: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-left: 10px;
        background-image: url(../img/icons/arrow-accordion.svg);
      }

    }

    .accordion-item-content {
      overflow: hidden;
      width: 100%;
      transition: height 0.5s ease-in-out;
      height: 0;

      .accordion-content-wrapper {
          padding: 13px 30px 20px;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 22px;
    }
  }

  .accordion-item.active {
    transition: all 2s;

    .accordion-item-header {
      color: $--color-white;
      background-color: $--color-purple;

      &:after {
        background-image: url(../img/icons/arrow-accordion-active.svg);
      }
    }

    .accordion-item-content {
      transition: height 0.5s ease-in-out;
    }
  }

}

@media (max-width: 768px) {
  .accordion {

    .accordion-item {

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .accordion-item-header {
        padding: 20px;
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.08px;
      }

      .accordion-item-content {

        .accordion-content-wrapper {
          padding: 20px;
        }
      }

    }
  }
}
