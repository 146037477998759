.blog-section {
  margin: 30px 0 110px;


  .title-head {
    margin-bottom: 40px;
  }

  .blog-grid {
    width: 100%;
  }

  .blog-card {
    width: calc(33.33% - 30px);
    margin-right: 30px;
    margin-bottom: 30px;
    background-color: $--color-grey-light;
    padding: 30px;
    border-radius: 15px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .blog-card-author {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.175px;
      color: $--color-purple;
      margin-bottom: 30px;
    }

    .blog-card-title {
      font-size: 30px;
      font-weight: 500;
      line-height: 105%;
      letter-spacing: 0.3px;
      color: $--color-default;
      margin-bottom: 10px;

      &:hover {
        color: $--color-purple;
      }
    }

    .blog-card-description {
      margin-bottom: 40px;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        line-clamp: 6;
        box-orient: vertical;
      }
    }

    .blog-card-meta {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .blog-card-date {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.048px;
        color: $--color-grey;
      }

      .link-purple {

      }

    }
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .btn {
      max-width: 370px;
      cursor: pointer;
    }
  }

}

@media (max-width: 1024px) {
  .blog-section  {
    .blog-card {
      width: calc(50% - 30px);
    }
  }
}

@media (max-width: 600px) {
  .blog-section  {
    .blog-card {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}