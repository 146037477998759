.image-with-text {
  margin: 75px 0 150px;

  .block-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .description {

      p {
        font-weight: 400;
      }
    }
  }

  .image-wrapper {

    img {
      max-width: 470px;
      width: 100%;
      object-fit: cover;
    }
  }
  .image-wrapper-tablet, .image-wrapper-mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .image-with-text {
    margin: 100px 0;

    .image-wrapper  {

      img {
        max-width: 100%;
      }
    }

    .image-wrapper-pc {
      display: none;
    }

    .image-wrapper-tablet {
      display: block;
    }

  }
}

@media (max-width: 768px) {
  .image-with-text {
    margin: 60px 0;

    .title-block {
      margin-bottom: 10px;
    }

    .image-left {
      order: 1;
    }

    .image-wrapper-tablet {
      display: none;
    }

    .image-wrapper-mobile {
      display: block;
    }
  }
}
